import React, {useState} from "react";
import "./home.css";
import fingerprint from "./iconfingerprint.png";

export default function MyComponent(props) {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true); 
    let emails = ["qniwerniq@gmail.com"]; 

    const handleSubscribe = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);
      setIsValidEmail(isValid);
  
        if (isValid) {
            fetch("http://localhost:8080/", {
                method: "POST",
                body: JSON.stringify({
                    "email": email,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then((response) => response.json())
            .then((json) => alert(json));
        }
    };

    return (
        <div className="items-stretch flex flex-col">
        <div className="bg-black flex w-full flex-col items-stretch pl-10 pt-6 pb-12 max-md:max-w-full max-md:pl-5">
            <div className="logo text-white text-2xl font-semibold uppercase max-md:max-w-full">
            OMT ERP
            </div>
            <div className="mt-3 mb-4 max-md:max-w-full max-md:pr-5">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col items-stretch w-[45%] max-md:w-full max-md:ml-0">
                <div className="flex flex-col mt-28 items-start max-md:max-w-full max-md:mt-10">
                    <div className="hero">
                        <div className="text-white text-7xl leading-[80px] tracking-tighter self-stretch max-md:max-w-full max-md:text-4xl max-md:leading-[49px]">
                        Blockchain Based ERP Systems
                        </div>
                        <div className="text-stone-300 text-xl leading-8 self-stretch mt-5 max-md:max-w-full">
                        Demo will be ready up to the end of January 2024
                        </div>
                        
                        <div className="items-stretch flex gap-5 mt-10">
                        
                        <button className="text-white text-xl leading-8 whitespace-nowrap justify-center items-stretch bg-blue-700 grow px-9 py-2.5 rounded-[360px] max-md:px-5 hero_button">
                            Get started
                        </button>

                        <div className="justify-between items-stretch flex gap-2.5 px-5 py-2.5 rounded-[360px] max-md:pr-5">
                            
                            <div className="text-white text-xl leading-8 grow whitespace-nowrap">
                            Learn more
                            </div>
                            <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cbb8b08bc7bbe3ac562d4a183339a790ac62f9a8e7c94335061dbe03db70c6cd?"
                            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full mt-1.5 self-start"
                            />
                            
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="flex flex-col items-stretch w-[55%] ml-5 max-md:w-full max-md:ml-0">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ed589da2edf5475de24c9efb2967290b39909f285b761e7ffbe056d9fc971d6?"
                    className="aspect-[1.17] object-contain object-center w-full overflow-hidden grow max-md:max-w-full"
                />
                </div>
            </div>
            </div>
        </div>
        <div className="bg-black flex w-full flex-col px-20 py-12 max-md:max-w-full max-md:px-5">
            <div className="items-center self-stretch flex flex-col mt-8 mx-10 pb-12 px-16 rounded-3xl border-t-sky-700 border-t-opacity-10 border-x-sky-700 border-x-opacity-10 border-t border-solid border-r border-l max-md:max-w-full max-md:mr-2.5 max-md:px-5">
            <div className="flex w-[800px] max-w-full flex-col items-center mb-8">
                <h2 className="text-stone goal">Our goal</h2>
                <hr />
                <div className="text-stone-300 text-center text-lg leading-7 self-stretch mt-10 max-md:max-w-full">
                Our Goal is to change the way businesses are running. Our
                Product is blockchain based ERP systems, which makes ERP more
                secured, trusted and autonomous. We want to use innovating
                blockchain technology for this purpose. Populate and make
                blockchain unchanging part of each self-respecting and honest
                business.
                </div>
            </div>
            </div>
            <div className="text-white text-5xl leading-[60px] tracking-tighter self-center whitespace-nowrap mt-24 max-md:text-4xl max-md:mt-10">
            Solutions We Provide
            </div>
            <div className="self-stretch mt-20 max-md:max-w-full max-md:mt-10">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                <div className="justify-end max-md:max-w-full max-md:mt-10 max-md:pl-5">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[22%] max-md:w-full max-md:ml-0">
                        <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/77a292b534d29953bd296d7f4eaf569fc16fead4813e06efbc81b5018f2fbe37?"
                        className="aspect-[1.07] object-contain object-center w-28 overflow-hidden shrink-0 max-w-full mt-5 max-md:mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[78%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="items-stretch flex grow flex-col max-md:mt-10">
                        <div className="text-white text-2xl leading-8">
                            DATA SECURITY
                        </div>
                        <div className="text-stone-300 text-lg leading-7 mt-4">
                            There is no need to explain how data leaking effect Your
                            company.  We use permissioned (private) blockchain
                            technology to interact with data. It forbids interacting
                            for everyone, except of allowed users. Every day we are
                            improving our Product, making it more and more secure.
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                <div className="justify-end grow max-md:max-w-full max-md:mt-10 max-md:pl-5">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-1/5 max-md:w-full max-md:ml-0">
                        <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bc64c8238c963b72008bf7fe9a51158a828fb6f5a302ce23385060b4b3e6aac9?"
                        className="aspect-[1.2] object-contain object-center w-[100px] stroke-[0.5px] stroke-black overflow-hidden shrink-0 max-w-full mt-8 max-md:mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-4/5 ml-5 max-md:w-full max-md:ml-0">
                        <div className="items-stretch flex grow flex-col max-md:mt-10">
                        <div className="text-white text-center text-2xl leading-8">
                            TRUST
                        </div>
                        <div className="text-stone-300 text-lg leading-7 mt-4">
                            Blockchain-based smart contracts in ERP systems offer
                            unparalleled advantages, streamlining processes with
                            enhanced transparency, security, and efficiency. These
                            self-executing contracts automate actions, reduce fraud
                            risk through decentralized and tamper-resistant ledgers,
                            ensure precision, cut costs by eliminating
                            intermediaries, and provide a trustworthy, auditable
                            transaction history.
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="justify-end self-stretch mt-16 max-md:max-w-full max-md:mt-10 max-md:pl-5">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 fingerprint">
                <div className="flex flex-col items-stretch w-[16%] max-md:w-full max-md:ml-0 ">
                <img
                        src={fingerprint}
                        className="aspect-square object-contain object-center w-28 overflow-hidden shrink-0 max-w-full my-auto max-md:mt-10"
                />
                </div>
                <div className="flex flex-col items-stretch w-[84%] ml-5 max-md:w-full max-md:ml-0">
                <div className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-10">
                    <div className="text-white text-2xl leading-8 max-md:max-w-full">
                    ACCESS RIGHTS
                    </div>
                    <div className="text-stone-300 text-lg leading-7 mt-4 max-md:max-w-full">
                    In traditional ERP’s anyone with access can change database
                    records. In our Product, only allowed circle of users can
                    interact with data.{" "}
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="items-center bg-black flex w-full flex-col justify-center px-16 py-12 max-md:max-w-full max-md:px-5">
            <div className="flex w-full max-w-[1216px] flex-col items-stretch mt-28 max-md:max-w-full max-md:mt-10">
            <div className="items-stretch flex justify-between gap-5 max-md:max-w-full max-md:flex-wrap">
                <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
                <div className="text-white text-xl leading-8 max-md:max-w-full">
                    Join our newsletter
                </div>
                <div className="text-stone-300 text-base leading-6 mt-2 max-md:max-w-full">
                    Enter Your Email if you want to be notified when we launch!
                </div>
                </div>
                <div className="items-stretch flex gap-4 self-start">
                <input  value={email} onChange={(e) => setEmail(e.target.value)} className="text-neutral-500 text-base leading-6 whitespace-nowrap items-stretch border border-[color:var(--default-200,#757575)] shadow-sm bg-neutral-950 grow justify-center px-3.5 py-3.5 rounded-lg border-solid" />
                <button onClick={handleSubscribe} className="text-white text-xl leading-8 whitespace-nowrap justify-center items-stretch border border-[color:var(--primary-600,#004EE7)] shadow-sm bg-blue-700 px-5 py-2.5 rounded-lg border-solid">
                    Subscribe
                </button>
                </div>
            </div>
            <div className="items-stretch flex justify-between gap-5 mt-20 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
                <div className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-neutral-500 text-sm leading-5 whitespace-nowrap">
                    Product
                </div>
                <div className="text-stone-300 text-base leading-6 mt-4">
                    Overview
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Features
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Solutions
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Tutorials
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Pricing
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Releases
                </div>
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-neutral-500 text-sm leading-5 whitespace-nowrap">
                    Company
                </div>
                <div className="text-stone-300 text-base leading-6 mt-4">
                    About us
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Careers
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Press
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    News
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Media kit
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Contact
                </div>
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-neutral-500 text-sm leading-5 whitespace-nowrap">
                    Resources
                </div>
                <div className="text-stone-300 text-base leading-6 mt-4">
                    Blog
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Newsletter
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Events
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Help centre
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Tutorials
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Support
                </div>
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-neutral-500 text-sm leading-5 whitespace-nowrap">
                    Use cases
                </div>
                <div className="text-stone-300 text-base leading-6 mt-4">
                    Startups
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Enterprise
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Government
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    SaaS
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Marketplaces
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Ecommerce
                </div>
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-neutral-500 text-sm leading-5 whitespace-nowrap">
                    Social
                </div>
                <div className="text-stone-300 text-base leading-6 mt-4">
                    Twitter
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    LinkedIn
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Facebook
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    GitHub
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    AngelList
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Dribbble
                </div>
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-neutral-500 text-sm leading-5 whitespace-nowrap">
                    Legal
                </div>
                <div className="text-stone-300 text-base leading-6 mt-4">
                    Terms
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Privacy
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Cookies
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Licenses
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Settings
                </div>
                <div className="text-stone-300 text-base leading-6 mt-3">
                    Contact
                </div>
                </div>
            </div>
            <div className="justify-between items-stretch flex gap-5 mt-20 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
                <div className="text-white text-2xl font-semibold uppercase">
                OMT ERP
                </div>
                <div className="text-neutral-500 text-base leading-6 self-center grow shrink basis-auto my-auto">
                © 2023 OMT ERP. All rights reserved.
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

