import './App.css';
import MyComponent from './components/home';


function App() {
  return (
    <div className="App">
      <MyComponent />        
    </div>
  );
}

export default App;
